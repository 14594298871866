import React from "react"
import { Helmet } from "react-helmet"

import Layout from "layouts/default"
import Header from "components/components/header"

const FourZeroFourPage = () => (
  <Layout>
    <Helmet>
      <title>Page not found</title>
    </Helmet>
    <Header />
  </Layout>
)

export default FourZeroFourPage
