import React from "react"
import { Link } from "gatsby"

import { camelize } from "components/utils"
import Svg from "components/svgs/svg"

import cx from "classnames"
import * as s from "./header.module.sass"

const Header = (props) => {
  const { version, parentClass } = props

  const componentClass = cx({
    [s.component]: true,
    [s[camelize(`version-${version}`)]]: version,
    [parentClass]: parentClass,
  })

  const svgId = version === "index" ? "nsLogoLight" : "nsLogo"

  return (
    <div className={componentClass}>
      <Link to={`${process.env.SITE_URL}/`} className={s.logo}>
        <Svg parentClass={s.svg} id={svgId} />
      </Link>

      <nav className={s.nav}>
        {PAGES.map((page) => {
          return (
            <Link
              key={page.id}
              className={s.link}
              activeClassName={s.active}
              to={page.path}
              partiallyActive={page.partiallyActive}
            >
              {page.label}
            </Link>
          )
        })}
      </nav>
    </div>
  )
}

const PAGES = [
  {
    id: "home",
    label: "Home",
    path: "/",
  },
  {
    id: "team",
    label: "Team",
    path: "/team/",
  },
  {
    id: "diversity",
    label: "Diversity",
    path: "/diversity/",
  },
  {
    id: "blog",
    label: "Blog",
    path: "/blog/",
    partiallyActive: true,
  },
]

export default Header
